const registerGoogleMap = () => {
	// Declare map
	const googleMap = document.querySelectorAll('#map');

	// Google Maps
	googleMap.forEach((element) => {
		function createMap(el, lat, long, zoom, marker) {
			// Set a center point of the map, can be re-evaluated using `centerMap()`
			const center = { lat: lat, lng: long };

			const mapOptions = {
				zoom: zoom,
				center: center,
				fullscreenControl: false,
			};

			// Create a map instance.
			const melonMap = new MelonMap(document.getElementById(el), mapOptions);

			// Marker locations [name, lat, long, zindex]
			const markerLocation = [['Kentish Building Surveying', lat, long, 1]];

			if (marker == true) {
				markerLocation.forEach((markerLocation) => {
					const title = markerLocation[0];

					const location = {
						lat: parseFloat(markerLocation[1]),
						lng: parseFloat(markerLocation[2]),
					}

					melonMap.addMarker(location, title);
				});
			}

			// Add a map style
			melonMap.setMapStyle(
				[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
				{ name: 'Styled Map' }
			);
		}

		// Initiate google map for contact form
		createMap('map', 51.633031, -0.5714076, 12, true);
	});
}

var doc = document.documentElement;
var w = window;

var prevScroll = w.scrollY || doc.scrollTop;
var curScroll;
var direction = 0;
var prevDirection = 0;

var header = document.getElementById('site-header');


var checkScroll = function () {

	/*
	** Find the direction of scroll
	** 0 - initial, 1 - up, 2 - down
	*/

	curScroll = w.scrollY || doc.scrollTop;
	if (curScroll > prevScroll) {
		//scrolled up
		direction = 2;
	}
	else if (curScroll < prevScroll) {
		//scrolled down
		direction = 1;
	}

	if (direction !== prevDirection) {
		toggleHeader(direction, curScroll);
	}

	prevScroll = curScroll;
};

var toggleHeader = function (direction, curScroll) {
	if (direction === 2 && curScroll > 100) {

		//replace 52 with the height of your header in px

		header.classList.add('hide');
		prevDirection = direction;
	}
	else if (direction === 1) {
		header.classList.remove('hide');
		prevDirection = direction;
	}
};

window.addEventListener('scroll', checkScroll);

$(window).on('scroll', function () {
	var scroll = $(window).scrollTop();

	if (scroll > 150) {
		$('.header-main').addClass('mod-bg');
		$('.logo img.mod-white').addClass('mod-hide');
		$('.logo img.mod-black').removeClass('mod-hide');
	} else {
		$('.header-main').removeClass('mod-bg');
		$('.logo img.mod-white').removeClass('mod-hide');
		$('.logo img.mod-black').addClass('mod-hide');
	}
});


document.addEventListener('DOMContentLoaded', () => {
	registerGoogleMap();

	const body = document.querySelector('body');

	// Helper function
	let domReady = (cb) => {
		document.readyState === 'interactive' || document.readyState === 'complete'
			? cb()
			: document.addEventListener('DOMContentLoaded', cb);
	};

	domReady(() => {
		// Display body when DOM is loaded
		body.style.visibility = 'visible';
	});

	// // Google Recaptcha v3
	// grecaptcha.ready(function () {
	// 	document.querySelectorAll('form.mod-recaptcha').forEach((element) => {
	// 		grecaptcha.execute('6Le3scIZAAAAAOT2o50REZN2_JuOFanbYKkUz-de', { action: 'submit' }).then(function (token) {
	// 			$('<input>').attr({
	// 				type: 'hidden',
	// 				name: 'g-recaptcha-response',
	// 				value: token
	// 			}).appendTo(element)
	// 		});
	// 	});
	// });

	// hide cookie policy
	document.querySelector('.section-cookies .btn-accept').addEventListener('click', (e) => {
		e.preventDefault();

		// If no validation errors
		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 1,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	document.querySelector('.section-cookies .btn-decline').addEventListener('click', (e) => {
		e.preventDefault();

		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 0,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	// Add class to inputs when clicked on add effect
	const form = document.querySelectorAll('form');

	if (form) {
		document.querySelectorAll('form input, form textarea').forEach((element) => {
			element.addEventListener('focus', () => {
				element.parentElement.classList.add('mod-filled');
			});

			element.addEventListener('blur', () => {
				if (element.value) {
					element.parentElement.classList.add('mod-filled');
				} else {
					element.parentElement.classList.remove('mod-filled');
				}
			});
		});
	}

	const contactPage = document.querySelectorAll('#contact'),
		contactForm = document.querySelector('#contactForm');

	if (contactPage) {

		if (contactForm) {
			// Ajax contact form processing
			contactForm.addEventListener('submit', function (event) {
				event.preventDefault();


				const spamField = document.querySelectorAll('input#Form_Email'),
					loadingSpinner = document.querySelectorAll('.section-form .loading');

				// if honeypot field is filled in by spambot, do not submit
				if (spamField[0].value) {
					return false;
				}

				loadingSpinner[0].classList.add('mod-show');

				$.ajax({
					method: 'POST',
					url: 'process/contact/',
					dataType: 'json',
					data: $(this).serialize()
				}).done(function (response) {
					// If no errors
					if (response == 'success') {
						loadingSpinner[0].classList.remove('mod-show');

						// Remove filled class on inputs
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-filled');
						});

						// Hide form temporarily
						contactForm.classList.add('mod-hide');

						// Show thank you message
						contactForm.nextElementSibling.classList.add('mod-show');

						// Remove any errors that may still be there
						contactForm.firstElementChild.innerHTML = '';

						// Remove any values in inputs that would be still in there after submission
						document.querySelectorAll('#contactForm .form-element input, #contactForm .form-element textarea').forEach((element) => {
							element.value = '';
						});

						// Empty all error texts
						document.querySelectorAll('#contact .sub-error').forEach((element) => {
							element.innerHTML = '';
						});

						// Remove error class
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-error');
						});

						// Hide thank you message after 10 seconds and return to form
						setTimeout(function () {
							contactForm.classList.remove('mod-hide');
							contactForm.nextElementSibling.classList.remove('mod-show');
						}, 10000);

					}
					else {
						loadingSpinner[0].classList.remove('mod-show');

						contactForm.firstElementChild.innerHTML = 'Please fix the errors below:';

						// Empty any error messages that don't need it
						document.querySelectorAll('#contactForm .sub-error').forEach((element) => {
							element.innerHTML = '';
						});

						// Remove error class
						document.querySelectorAll('#contactForm .form-element').forEach((element) => {
							element.classList.remove('mod-error');
						});

						for (const [key, value] of Object.entries(response)) {
							if (key) {
								// Implement error message to specific input that needs it
								document.querySelectorAll('#contactForm .sub-error[data-error="' + key + '"]')[0].innerHTML = response[key]['message'];

								// Add error class to form element for styling
								if (key !== 'recaptcha') {
									document.querySelectorAll('#contactForm .form-element.mod-' + key)[0].classList.add('mod-error');
								}
							}
						}
					}
				});
			});
		}
	}

	$('.section-customer-feedback .list-feedback').slick({
		appendDots: $('.feedback-dots'),
		arrows: false,
		autoplay: true,
		autoplaySpeed: 7000,
		adaptiveHeight: true,
		dots: true,
		draggable: false,
		fade: true,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		responsive: [
			{
				breakpoint: 768,
				adaptiveHeight: true
			}
		]
	});


	// Ensure the function exists on the page before we call it.
	if (!!window['gdprSubscribeModal']) {
		// The form of which to attach the modal to.
		const formSelector = '#contact-form';
		// The url to link to for the privacy policy.
		const privacyPolicyURL = 'privacy-policy.html';
		// Color of the links in the modal
		const linkColour = '#000';
		// Color of the buttons in the modal.
		const buttonTextColour = '#000';

		// Call this per form.
		gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
	}

	// Pops out share modal window
	document.querySelectorAll('.social-share').forEach((element) => {
		element.addEventListener('click', (e) => {

			// Prevents default action
			e.preventDefault();
			// Runs window popup function
			windowPopup($(this).attr("href"), 500, 300);
		});
	});


	// Function for popout window
	function windowPopup(url, width, height) {
		// Calculate the position for the popup so it’s centered on the screen.
		const left = (screen.width / 2) - (width / 2);
		const top = (screen.height / 2) - (height / 2);

		// opens a window with the given url & set position from above
		window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
	}

	$('.mob-hamburger').click(function (e) {
		e.preventDefault();

		// Add active class to hamburger so it can be animated
		$(this).toggleClass('mod-active');

		// Add active class to nav to show
		$('#nav').removeClass('mod-hide');

		// Add no scroll class to body to prevent scrolling when nav is active
		$('body').toggleClass('no-scroll');

		// Add active class to header
		$('.header-main').toggleClass('mod-active');

		// Add active class on all li's
		if (!$(this).hasClass('mod-active')) {
			$('#nav ul li').addClass('mod-hide');
			setTimeout(function () {
				$('#nav').addClass('mod-hide');
			}, 550);
		} else {
			setTimeout(function () {
				$('#nav ul li').removeClass('mod-hide');
			}, 900);
		}
	});

	$('.list-nav li.mod-dropdown > a').click(function (e) {
		e.preventDefault();

		$(this).next('ul.sub-sub-menu').toggleClass('mod-active');
	});

	/**
	 * Scroll down 1 section when clicking arrow on home arrow
	 */

	$('.section-hero .sub-arrow').on('click', function (ev) {
		ev.preventDefault();

		$('html, body').animate({ scrollTop: $('#content').offset().top }, 1000, 'swing');
	});

});
